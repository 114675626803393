import React, { Fragment, useRef, useState } from 'react'
import {
    AmountWrapper,
    CommonHolder, CommonSubTitleMenu, CommonSubTitleMenuValue, CommonTitleH1, ShopName,
    CommonTitleH2, CommonTitleH3, CommonTitleHolder, ContentHolder, Symbol, SignatureWrapper, Sign
} from './Styles'
import { Row } from '../../../../Components/Row';
import { Col } from 'antd';
import Flex from '../../../../Components/Flex';
import dayjs from 'dayjs'
import { useReactToPrint } from 'react-to-print';
import Button from '../../../../Components/Form/Button';

export const ModalViewContent = ({ record, relievingDate }) => {
    const [currentDate, setCurrentDate] = useState(dayjs());

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const formattedCurrentDate = dayjs(currentDate).format('DD-MM-YYYY');
    const formattedDate = dayjs(record?.booking_date).format('DD-MM-YYYY');
    const formattedRelevingDate = dayjs(relievingDate).format('DD-MM-YYYY');
    const formattedAmount = record?.advance.toLocaleString('en-IN', { minimumFractionDigits: 2 });

    return (
        <Fragment>

            <Button.Primary onClick={handlePrint} text={'Click To Print'} />

            <CommonHolder ref={componentRef}>
                <CommonTitleHolder>
                    <CommonTitleH1>
                        HOTEL SHIVAS RESIDENCY
                    </CommonTitleH1>

                    <CommonTitleH3>
                        SOUTH CAR STREET,&nbsp;KANYAKUMARI District,&nbsp;Pin&nbsp;:&nbsp;629 702
                    </CommonTitleH3>

                    <CommonTitleH3>
                        Phone&nbsp;No:&nbsp;:&nbsp;04652-246150
                    </CommonTitleH3>

                    <CommonTitleH3>
                        E-mail&nbsp;:hotelshivasresidency@gmail.com
                    </CommonTitleH3>

                    <CommonTitleH2>
                        INVOICE
                    </CommonTitleH2>

                </CommonTitleHolder>

                <ContentHolder>
                    <Row gutter={[24, 24]}>
                        <Col span={24} md={12}>
                            <Flex W_100>
                                <CommonSubTitleMenu>RoomNo&nbsp;:</CommonSubTitleMenu>
                                <CommonSubTitleMenuValue> {record.roomNo !== 0 && <span>{record.roomNo}</span>}
                                    {record.multi_rooms.length !== 0 &&
                                        record.multi_rooms.map((room,index) => (
                                            <span key={index}> {room},&nbsp;</span>
                                        ))
                                    }</CommonSubTitleMenuValue>
                            </Flex>
                        </Col>
                        <Col span={24} md={12}>
                            <Flex W_100>
                                <CommonSubTitleMenu>Date&nbsp;:&nbsp;</CommonSubTitleMenu>
                                <CommonSubTitleMenuValue>{formattedCurrentDate}</CommonSubTitleMenuValue>
                            </Flex>
                        </Col>
                        <Col span={24} md={12}>
                            <Flex W_100>
                                <CommonSubTitleMenu>name&nbsp;:&nbsp;</CommonSubTitleMenu>
                                <CommonSubTitleMenuValue>{record.name}</CommonSubTitleMenuValue>
                            </Flex>
                        </Col>
                        {/* <Col span={24} md={12}>
                            <Flex W_100>
                                <CommonSubTitleMenu>Address&nbsp;:&nbsp;</CommonSubTitleMenu>
                                <CommonSubTitleMenuValue>{record.location}</CommonSubTitleMenuValue>
                            </Flex>
                        </Col> */}

                        <Col span={24} md={12}>
                            <Flex W_100>
                                <CommonSubTitleMenu>PhoneNumber&nbsp;:&nbsp;</CommonSubTitleMenu>
                                <CommonSubTitleMenuValue>{record.phone}</CommonSubTitleMenuValue>
                            </Flex>
                        </Col>

                        <Col span={24} md={12}>
                            <Flex W_100>
                                <CommonSubTitleMenu>No.of&nbsp;Days&nbsp;:</CommonSubTitleMenu>
                                <CommonSubTitleMenuValue>{record?.no_of_days}</CommonSubTitleMenuValue>
                            </Flex>
                        </Col>
                        <Col span={24} md={12}>
                            <Flex W_100>
                                <CommonSubTitleMenu>No.of&nbsp;Person&nbsp;:&nbsp;</CommonSubTitleMenu>
                                <CommonSubTitleMenuValue>{record?.no_of_person}</CommonSubTitleMenuValue>
                            </Flex>
                        </Col>

                        <Col span={24} md={12}>
                            <Flex W_100>
                                <CommonSubTitleMenu>Check&nbsp;In&nbsp;:</CommonSubTitleMenu>
                                <CommonSubTitleMenuValue>{formattedDate}</CommonSubTitleMenuValue>
                            </Flex>
                        </Col>
                        <Col span={24} md={12}>
                            <Flex W_100>
                                <CommonSubTitleMenu>Check&nbsp;Out&nbsp;:&nbsp;</CommonSubTitleMenu>
                                <CommonSubTitleMenuValue>{formattedRelevingDate}</CommonSubTitleMenuValue>
                            </Flex>
                        </Col>

                        <Col span={24} md={12} style={{ marginTop: '15px' }}>
                            <AmountWrapper >
                                <Symbol style={{ color: 'white' }}>
                                    &#x20B9;
                                </Symbol>
                                <h4>
                                    {formattedAmount}
                                </h4>
                            </AmountWrapper>
                        </Col>
                        <Col span={24} md={12}>
                            <SignatureWrapper>
                                <ShopName>
                                    HOTEL SHIVAS RESIDENCY
                                </ShopName>

                                <Sign>
                                    Authorised Signatory
                                </Sign>
                            </SignatureWrapper>
                        </Col>
                    </Row>
                </ContentHolder>

            </CommonHolder>
        </Fragment>
    )
}
