import React, { useEffect, useState } from 'react'
import { FormTitle } from '../../../../Components/FormTitle'
import { Table } from '../../../../Components/Table'
import { Tag } from "antd";
import { Fragment } from "react";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { Modal } from '../../../../Components/Modal';
import { RoomsFillForm } from '../../AddRooms/Partials/RoomsFillForm';
import { IconHolder } from '../../../../Components/CommonStyle';
import request from '../../../../utils/request';
import { CustomSpin } from '../../../../Components/CustomSpin';

export const RoomsTable = () => {

    const [roomsData, setRoomsData] = useState([])
    const [reload, setReload] = useState(false)

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchRooms = () => {
        request.get('get').then((response) => {
            setRoomsData(response.data)
        }).catch(error => {
            console.log(error);
        });
    };

    const Updatefielscheck = () => {
        fetchRooms()
    }

    useEffect(() => {
        fetchRooms();
    }, [])

    useEffect(() => {
        fetchRooms();
    }, [reload])


    const deleteRoom = (record) => {
        request.delete(`home/delete/${record.roomNo}`).then((response) => {
            setReload(!reload)
            // setCustomerList(response.data)
        }).catch(error => {
            console.log(error);
        });
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const TableColumn = [
        {
            title: <span>Sl&nbsp;No</span>,
            render: (value, item, index) => index + 1,
        },
        {
            title: <span>Room&nbsp;No</span>,
            dataIndex: 'roomNo',
        },
        {
            title: <span>Ac&nbsp;Type</span>,
            dataIndex: 'ac_or_nonac',
        },
        {
            title: <span>Cleaning&nbsp;Status</span>,
            dataIndex: 'cleaned_uncleaned',
            render: (status, _) => {
                if (status) {
                    return (
                        <h1>True</h1>
                    )
                }
                else {
                    return (
                        <h1>false</h1>
                    )
                }
            }
        },
        {
            title: 'Floor',
            dataIndex: 'floor_details',
            render: (_, record) => (
                <Fragment>
                    {record.floor_details == 'A' && <span>GROUND FLOOR</span>}
                    {record.floor_details == 'B' && <span>FIRST FLOOR</span>}
                    {record.floor_details == 'C' && <span>SECOND FLOOR</span>}
                </Fragment>
            )
        },
        {
            title: 'Beds',
            dataIndex: 'no_of_bed',
        },
        {
            title: 'Categories',
            dataIndex: 'category',
            render: (_, { category }) => (
                <Fragment>
                    {
                        category.map((cate) => {
                            return (
                                // <Category>{cate}</Category>
                                <Tag color={'geekblue'} style={{ fontSize: '12px', }} key={cate}>
                                    {cate.toUpperCase()}
                                </Tag>
                            )
                        })
                    }
                </Fragment>
            )
        },
        {
            title: 'Action',
            render: (record) => (
                <Fragment>
                    <IconHolder onClick={() => EditRooms(record)}>
                        <FiEdit color="green" />
                    </IconHolder>
                    {/* <IconHolder onClick={() => DeleteRooms(record)}>
                        <AiOutlineDelete color="red" />
                    </IconHolder> */}
                </Fragment>
            )
        }
    ]

    const EditRooms = (record) => {
        setModalTitle("Edit Rooms");
        setModalContent(<RoomsFillForm roomdata={record} Updatefielscheck={Updatefielscheck} handleOk={handleOk} />);
        showModal();
    }

    const DeleteRooms = (record) => {
        deleteRoom(record)
    }

    const rowkey = (roomsData) => roomsData.roomNo;
    return (
        <div>
            {
                roomsData.length != 0 ?
                    (
                        <div>
                            <FormTitle title={'View Rooms'} />
                            <Table columns={TableColumn} data={roomsData} rowKey={rowkey} />
                            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={600} modalTitle={modalTitle} modalContent={modalContent} />
                        </div>
                    )
                    :
                    (<CustomSpin />)
            }
        </div>

    )
}

