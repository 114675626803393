import React, { Fragment } from 'react'
import { RoomsFillForm } from './Partials/RoomsFillForm'
import { FormTitle } from '../../../Components/FormTitle'
import Flex from '../../../Components/Flex'
import { Col } from 'antd'
import CustomCard from '../../../Components/CustomCard'

export const AddRooms = () => {
    return (
        <Fragment>
            <FormTitle title={'Add Rooms'} />
            <Flex center>
                <Col span={24} lg={16}>

                    <CustomCard>
                        <RoomsFillForm />
                    </CustomCard>
                </Col>
            </Flex>
        </Fragment>
    )
}

