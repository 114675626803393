import React from 'react'
import { Spin } from 'antd';

export const CustomSpin = () => {
  return (
  
    <Spin size="large" />
  )
}

