
export const OneRoomsData = [
    {
        key: '1',
        room_no: 101,
        naac_or_nonac: true,
        cleaned_uncleaned: true,
        floor_details: 'Ground Floor',
        category: ['Normal', 'Super Dulex']
    },
    {
        key: '2',
        room_no: 102,
        naac_or_nonac: false,
        cleaned_uncleaned: false,
        floor_details: 'Ground Floor',
        category: ['Delux', 'Super Dulex']
    },
    {
        key: '3',
        room_no: 103,
        naac_or_nonac: false,
        cleaned_uncleaned: false,
        floor_details: 'Ground Floor',
        category: ['Delux', 'Super Dulex', 'Suite',]
    },
    {
        key: '4',
        room_no: 201,
        naac_or_nonac: true,
        cleaned_uncleaned: true,
        floor_details: 'First Floor',
        category: ['Normal', 'Super Dulex']
    },
    {
        key: '5',
        room_no: 202,
        naac_or_nonac: false,
        cleaned_uncleaned: false,
        floor_details: 'First Floor',
        category: ['Delux', 'Super Dulex']
    },
    {
        key: '6',
        room_no: 301,
        naac_or_nonac: false,
        cleaned_uncleaned: false,
        floor_details: 'Second Floor',
        category: ['Delux', 'Super Dulex', 'Suite',]
    },
]

// export const RoomsData = [
//     {
//         key: '1',
//         floor_details: 'ground',
//         room_details: [
//             {
//                 key: '1',
//                 room_no: 101,
//                 naac_or_nonac: false,
//                 cleaned_uncleaned: true,
//                 vacate:false,
//                 available:false,
//                 category: ['Delux'],
//                 booked: true,
//             },
//             {
//                 key: '2',
//                 room_no: 102,
//                 naac_or_nonac: true,
//                 cleaned_uncleaned: false,
//                 vacate:true,
//                 available:false,
//                 category: ['Super Dulex', 'Suite',],
//                 booked: false,
//             },
//             {
//                 key: '3',
//                 room_no: 103,
//                 naac_or_nonac: true,
//                 cleaned_uncleaned: true,
//                 vacate:false,
//                 available:true,
//                 category: ['Super Dulex', 'Suite',],
//                 booked: false,
//             },
//         ]
//     },
//     {
//         key: '2',
//         floor_details: 'first',
//         room_details: [
//             {
//                 key: '1',
//                 room_no: 201,
//                 naac_or_nonac: false,
//                 cleaned_uncleaned: false,
//                 category: ['Delux', 'Super Dulex', 'Suite',],
//                 booked: true,
//             },
//             {
//                 key: '2',
//                 room_no: 202,
//                 naac_or_nonac: false,
//                 cleaned_uncleaned: true,
//                 category: ['Delux', 'Super Dulex', 'Suite',],
//                 booked: true,
//             },

//             {
//                 key: '3',
//                 room_no: 203,
//                 naac_or_nonac: false,
//                 cleaned_uncleaned: true,
//                 category: ['Delux', 'Super Dulex', 'Suite',],
//                 booked: true,
//             },

//             {
//                 key: '4',
//                 room_no: 204,
//                 naac_or_nonac: false,
//                 cleaned_uncleaned: false,
//                 category: ['Delux', 'Super Dulex', 'Suite',],
//                 booked: false,
//             },
//         ]
//     },
//     {
//         key: '3',
//         floor_details: 'Second',
//         room_details: [
//             {
//                 key: '1',
//                 room_no: 301,
//                 naac_or_nonac: false,
//                 cleaned_uncleaned: false,
//                 category: ['Delux', 'Super Dulex', 'Suite',],
//                 booked: false,
//             },
//             {
//                 key: '2',
//                 room_no: 302,
//                 naac_or_nonac: true,
//                 cleaned_uncleaned: true,
//                 category: ['Delux', 'Super Dulex', 'Suite',],
//                 booked: false,
//             },
//         ]
//     },
// ]


export const cusName = [
    {
        label: 'Arun',
        value: 'Arun',
    },
    {
        label: 'Vignesh',
        value: 'Vignesh',
    },
    {
        label: 'Abin Kumar',
        value: 'Abin Kumar',
    },
    {
        label: 'Benny',
        value: 'Benny',
    },
]

export const CleanUpdate = [
    {
        label: 'Cleaned',
        value: 'Cleaned',
    },
    {
        label: 'UnCleaned',
        value: 'UnCleaned',
    },
]

export const MemberDetails = [
    {
        key: '1',
        value: 'men',
        name: 'men',
        title: 'No. Men'
    },
    {
        key: '2',
        value: 'women',
        name: 'women',
        title: 'No. Women'
    },
    {
        key: '3',
        value: 'children',
        name: 'children',
        title: 'No. Children'
    },
]

export const StatusColorCode = [
    {
        key: 1,
        color: '#E00505',
        name: 'Room Booked',
    },
    {
        key: 2,
        color: '#003366',
        name: 'Vacated & Not Cleaned',
    },
    {
        key: 3,
        color: '#39B203',
        name: 'Ready for Booking',
    },
    {
        key: 4,
        color: '#BFC6F2',
        name: 'Room Cleaned',
    },
    {
        key: 5,
        color: '#F2EBBF',
        name: 'Room Not Cleaned',
    },
]

