import React, { useEffect, useState } from 'react'
import {
    MainContentWrapper,
    MainContentTitle,
    MainContentHolder,
    MainContentSubTitle,
    MainContentRoomsWrapper,
    MainContentRooms,
} from './Styled'

export const BookingRooms = ({ data, selectedroom }) => {

    const [activeClass, setActiveClass] = useState(null)

    useEffect(() => {
        setActiveClass(data[0]?.room_details[0]?.roomNo)
    }, [data])

    return (
        <MainContentWrapper>
            <MainContentTitle>Booking Rooms</MainContentTitle>
            {
                data.map((room,index) => (
                    <MainContentHolder key={index}>
                        <MainContentSubTitle>{room.floor == 'A' && 'GROUND FLOOR'} {room.floor == 'B' && 'FIRST FLOOR'} {room.floor == 'C' && 'SECOND FLOOR'}</MainContentSubTitle>
                        <MainContentRoomsWrapper>
                            {
                                room.room_details.map((item,index) => (
                                    <MainContentRooms key={index}
                                        className={[item.booking && 'booked', item.vacate && 'vacate', item.available && 'available', activeClass === item.roomNo ? 'active' : '']}
                                        onClick={() => {
                                            setActiveClass(item.roomNo);
                                            selectedroom(item.roomNo);
                                        }}>
                                        <span>{item.roomNo}</span>
                                        <span>{item.ac_or_nonac}</span>
                                        {/* <span>{item.booked ? 'true' : 'false'}</span> */}
                                    </MainContentRooms>
                                ))
                            }
                        </MainContentRoomsWrapper>
                    </MainContentHolder>
                ))
            }
        </MainContentWrapper>
    )
}
