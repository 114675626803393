import styled from "styled-components"
import { THEME } from '../../../../theme'

export const AmountWrapper = styled.div`
    width:100%;
    border:1px solid ${THEME.primary_color};
    display:flex;
    align-items:center;

    & h4{
    padding-left: 10px;
    }
`

export const Symbol = styled.span`
    background:${THEME.black};
    padding:5px 15px;
    color:#fff;
    font-family: none;
`

// ============  RECEIPT AND VOUCHER ==============
export const CommonHolder = styled.div`
    border:5px solid ${THEME.primary_color};
    box-shadow:inset 0 0 0 3px #fff,inset 0 0 0 5px ${THEME.primary_color};
    padding:10px;
    margin:10px;
    border-radius:10px;
`

export const CommonTitleHolder = styled.div`
    width:100%;
    color:${THEME.primary_color};
    text-align:center;
    margin-bottom:20px;
`

export const CommonTitleH1 = styled.h1`
    font-size:20px;
    letter-spacing:1px;
`

export const CommonTitleH3 = styled.h2`
    font-size:16px;
    letter-spacing:1px;
    font-weight:500;
`

export const CommonTitleH2 = styled.span`
    margin-top:5px;
    font-size:14px;
    text-transform:uppercase;
    color: #fff;
    background:${THEME.black};
    padding:2px 10px;
    font-weight:700;
    letter-spacing:1px;
`

export const ContentHolder = styled.div`
    padding:5px 10px;
    margin-bottom:10px;
`
export const CommonSubTitleMenu = styled.span`
    font-size:12px;
    text-transform:capitalize;
    padding:2px 10px;
    letter-spacing:1px;
    font-weight:600;
`

export const CommonSubTitleMenuValue = styled.div`
    font-size:12px;
    padding:2px 10px;
    letter-spacing:1px;
    font-weight:500;
    width:100%;
    border-bottom:1px solid;
`

export const SignatureWrapper = styled.div`
    text-align:center;
`


export const ShopName = styled.h4`
        font-size:12px;
        letter-spacing:1px;
        text-transform:capitalize;
`


export const Sign = styled.h4`
        font-size:12px;
        letter-spacing:1px;
        margin-top:35px;
`