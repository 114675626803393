import React from 'react'
import { VacateRoomsTable } from './Partials/VacateRoomsTable'

export const VacateRooms = () => {
  return (
    <div>
      <VacateRoomsTable />
    </div>
  )
}

