import React from 'react'
import { MainList } from './Styled'

export const Mainbox = ({secondbox}) => {
  return (
    <MainList>
      {secondbox}
    </MainList>
  )
}
