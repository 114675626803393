import request from '../../utils/request'

export const CUTOMER_LIST = 'CUTOMER_LIST'

export const setCustomer = customer => {
  return {
    type: CUTOMER_LIST,
    payload: customer,
  }
}

export const fetchCustomers = () => async dispatch => {
    try {
      const response = await request.get('customer')
      dispatch(setCustomer(response.data))
    } catch (error) {
      console.error('Getting error while fecting Products', error)
    }
  }
  
