export const genderData = [
    {
        label:'Male',
        value:'Male',
    },
    {
        label:'Female',
        value:'Female',
    },
]

export const verificationData = [
    {
        label:'Aadhaar',
        value:'aadhaar',
    },
    {
        label:'Driving Licence',
        value:'driving_licence',
    },
    {
        label:'Passport',
        value:'passport',
    },
]