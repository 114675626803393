import React, { useEffect, useState } from 'react'
import { BookingRooms } from './Partials/BookingRooms'
import { MainLayout } from '../../../Components/MainLayout/MainLayout'
import { RoomStatus } from './Partials/RoomStatus'
import { RoomsData } from './Partials/Data'
import { RoomBookingForm } from './Partials/RoomBookingForm'
import request from '../../../utils/request'


export const AddBooking = () => {

  const [roomsData, setRoomsData] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
const [trigger, setTrigger] = useState(0)

const UpdateTrigger = ()=>{
  setTrigger(trigger + 1);
}

  const fetchRooms = () => {
    request.get('home/get').then((response) => {
      setRoomsData(response.data)
      setSelectedRoom(response.data[0].room_details[0].roomNo)
    }).catch(error => {
      console.log(error);
    });
  };

  useEffect(() => {
    fetchRooms();
  }, [])

  useEffect(() => {
    fetchRooms();
  }, [trigger])

  

  const SelectedRoomData = (arg) => {
    setSelectedRoom(arg)
  }
  
  return (
    <MainLayout secondbox={<BookingRooms data={roomsData} selectedroom={SelectedRoomData} />} 
    sideBox={<RoomStatus data={roomsData} selectedRoom={selectedRoom} UpdateTrigger={UpdateTrigger}/>} />
    // <RoomBookingForm/>
  )
}