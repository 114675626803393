import React from 'react'
import { DashboardCardHolder } from './Styled'
import DashboardCard from '../../../Components/Card/DashboardCard'
import { DashCardData } from './Data'
import { Row } from '../../../Components/Row'
import { Col } from 'antd'
import { useState } from 'react'
import { useEffect } from 'react'
import request from '../../../utils/request'

const DashCard = () => {

    const [dashboardData, setDashboardData] = useState(DashCardData);

    useEffect(() => {
        // Fetch data from backend and update state
        fetchData();
    }, []);

    const fetchData = () => {
        request.get('dashboard/report')
            .then(function (response) {
                const data = response.data;
                setDashboardData((prevData) =>
                    prevData.map((item) => {
                        switch (item.name) {
                            case 'Total Customers':
                                return {
                                    ...item,
                                    value: data.total_customer|| 0,
                                };
                            case 'Total Rooms':
                                return {
                                    ...item,
                                    value: data.rooms|| 0,
                                };
                            case 'Available Rooms':
                                return {
                                    ...item,
                                    value: data.available|| 0,
                                };
                            case 'Booked Rooms':
                                return {
                                    ...item,
                                    value: data.booking_room|| 0,
                                };
                            default:
                                return item;
                        }
                    })
                );
            })
            .catch(function (error) {
                console.log(error, 'failedddd');
            });
    };

    return (
        <DashboardCardHolder>
            <Row gutter={[24, 24]}>
                {
                    dashboardData.map((item) => (
                        <Col key={item.key} span={24} lg={6} md={12} sm={12}>
                            <DashboardCard data={item} />
                        </Col>
                    ))
                }
            </Row>

        </DashboardCardHolder>
    )
}

export default DashCard
