import React, { useEffect, useState } from 'react'
import { Select as AntdSelect, Col, Form } from 'antd';
import Button from '../../../../Components/Form/Button';
import { CustomInputNumber } from '../../../../Components/Form/CustomInputNumber';
import Input from '../../../../Components/Form/Input';
import { CustomDatePicker } from '../../../../Components/Form/CustomDatePicker';
import { CategoryWrapper, FormSubTitle, LabelSelect, MainContentWrapper } from './Styled';
import { TextAreas } from '../../../../Components/Form/TextArea';
import Flex from '../../../../Components/Flex';
import { Row } from '../../../../Components/Row'
import Label from '../../../../Components/Form/Label';
import { CustomLabel } from '../../../../Components/CommonStyle';
import { Select } from '../../../../Components/Form/Select';
import { cusName } from './Data';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';

const options = [
  { label: <h3 style={{ background: 'green' }}>101 (Ac)</h3>, value: '101' },
  { label: <h3 style={{ background: 'red' }}>102 (Non -Ac)</h3>, value: '102' },
  { label: <h3 style={{ background: 'yellow' }}>hhh</h3>, value: '103' },

];


export const MultipleRoomBooking = ({ CloseModal, roomsFilter, UpdateTrigger }) => {

  const [selectedCount, setSelectedCount] = useState(0)
  const [getTime, setGetTime] = useState(null)
  const [customerList, setCustomerList] = useState([])
  const [phnoselect, setPhoselect] = useState({});
  const [form] = Form.useForm();

  const onFinish = (values) => {
    PostMultiBooking(values)
    toast.success('Rooms Booked Successfully')
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const PostMultiBooking = (values) => {
    request.post('save/book', values).then((response) => {
      CloseModal()
      UpdateTrigger();
    }).catch(error => {
      console.log(error);
    });
    // 
  }


  // roomsFilter

  const availableRooms = roomsFilter.map(user => ({ label: <LabelSelect className={user.ac_or_nonac === 'Ac' && 'ac'}>{user.roomNo}&nbsp;{user.ac_or_nonac}</LabelSelect>, value: user.roomNo }));

  const handleChange = (value) => {
    setSelectedCount(value.length)
  };

  const fetchCustomer = (values) => {
    request.get('customer').then((response) => {
      setCustomerList(response.data)
    }).catch(error => {
      console.log(error);
    });
  };


  useEffect(() => {
    fetchCustomer();
  }, [])

  useEffect(() => {
    form.setFieldsValue({ no_rooms: selectedCount })
  }, [selectedCount])

  useEffect(() => {
    form.setFieldsValue({ customer_id: phnoselect.customer_id })
    form.setFieldsValue({ name: phnoselect.name })
    form.setFieldsValue({ address: phnoselect.address })
    form.setFieldsValue({ phone: phnoselect.phone })

  }, [phnoselect])

  const BookingPhoneNo = customerList?.map(user => ({ label: user.name, value: user.name }));
  
  const handleSelected = (value) => {
    const selectedNumber = customerList.find((user) => user.name === value)
    setPhoselect(selectedNumber);
  }

  return (

    <MainContentWrapper>
      <Form name="room_booking"
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <FormSubTitle>Room Details</FormSubTitle>
        <hr style={{ marginBottom: '20px' }} />

        <Row gutter={[24, 24]}>

          <Col span={24} md={12}>

            <Form.Item
              name="multi_rooms"
              rules={[
                {
                  required: true,
                  message: 'Please Select Rooms!',
                }
              ]}
              label={<CustomLabel>Select Rooms</CustomLabel>}>
              <AntdSelect
                mode="multiple"
                allowClear
                style={{
                  width: '100%',
                }}
                size={'large'}
                placeholder="Please select"
                onChange={handleChange}
                options={availableRooms}
              />
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Input
              label={'Number of Rooms'}
              name={'no_rooms'}
              disabled
            />
          </Col>
        </Row>

        <FormSubTitle>Customer Details</FormSubTitle>
        <hr style={{ marginBottom: '20px' }} />

        <Row gutter={[24, 24]}>

          <Col span={24} md={12}>
            <Input
              label={'Customer Id'}
              name={'customer_id'}
              disabled
            />
          </Col>

          <Col span={24} md={12}>
            <Select
              label={'Customer Name'}
              name={'name'}
              options={BookingPhoneNo}
              showSearch
              onChange={handleSelected}
            />
          </Col>

          <Col span={24} md={12}>
            <Input
              label={'Phone Number'}
              name={'phone'}
              disabled
            />
          </Col>

          <Col span={24} md={12}>
            <TextAreas
              label={'Address'}
              name={'address'}
              disabled
            />
          </Col>
        </Row>

        <FormSubTitle>Travels Details</FormSubTitle>
        <hr style={{ marginBottom: '20px' }} />
        <Row gutter={[24, 24]}>

          <Col span={24} md={12}>
            <Input
              label={'Travel Name'}
              name={'travel_name'}
            />
          </Col>

          <Col span={24} md={12}>
            <Input
              label={'Location'}
              name={'location'}
            />
          </Col>
        </Row>

        <FormSubTitle>Staying Details</FormSubTitle>
        <hr style={{ marginBottom: '20px' }} />

        <Row gutter={[24, 24]}>

          <Col span={24} md={12}>
            <CustomDatePicker
              label={'Booking Date'}
              name={'booking_date'}
              showTime
              rules={[
                {
                  required: true,
                  message: 'Please select Date!',
                }
              ]} />
          </Col>

          <Col span={24} md={12}>
            <CustomInputNumber
              label={'No. Days'}
              name={'no_of_days'}
              required
              rules={[
                {
                  required: true,
                  message: 'Please fill Number of Days!',
                }
              ]}
            />
          </Col>

          <Col span={24} md={12}>
            <CustomInputNumber
              label={'No. Persons'}
              name={'no_of_person'}
              required
              rules={[
                {
                  required: true,
                  message: 'Please fill Number of Days!',
                }
              ]}
            />
          </Col>

          <Col span={24} md={12}>
            <CustomInputNumber
              label={'Advance Amount'}
              name={'advance'}
              required
              rules={[
                {
                  required: true,
                  message: 'Please fill Amount!',
                }
              ]}
            />
          </Col>


        </Row>

        <div style={{ margin: '30px' }}>
          <Flex W_100 center gap={'20px'}>
            <Button.Success text={'Save'} htmlType="submit" />
            <Button.Danger text={'Clear'} htmlType="reset" />
          </Flex>
        </div>

      </Form>
    </MainContentWrapper>
  )
}

