
import { FaBed, FaRegUser } from 'react-icons/fa'
import { Menu } from "antd";
import { useNavigate } from 'react-router-dom';
import { SideNavList, SubSideNavList } from "../../style";
import { useState } from 'react';
import { IoBedOutline } from 'react-icons/io5';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [
  getItem(<SideNavList>Dashboard</SideNavList>, ''),

  getItem(<SideNavList>Rooms</SideNavList>, 'sub1', <IoBedOutline style={{ fontSize: '16px' }} />, [
    getItem(<SubSideNavList>Add Rooms</SubSideNavList>, 'add_rooms'),
    getItem(<SubSideNavList>Edit Rooms</SubSideNavList>, 'view_rooms'),
  ]),

  getItem(<SideNavList>Customers</SideNavList>, 'sub2', <FaRegUser style={{ fontSize: '16px' }} />, [
    getItem(<SubSideNavList>Add Customers</SubSideNavList>, 'add_customer'),
    getItem(<SubSideNavList>Edit Customers</SubSideNavList>, 'view_customer'),
  ]),

  getItem(<SideNavList>Booking</SideNavList>, 'sub3', <FaBed style={{ fontSize: '16px' }} />, [
    getItem(<SubSideNavList>Booking Room</SubSideNavList>, 'add_booking'), 
    getItem(<SubSideNavList>Vacate Room</SubSideNavList>, 'vacate_room'), 
  ]),

];

// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub3'];


const SideMenu = () => {

  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const navigate = useNavigate();
  const onClick = ({ key }) => {

    if (key === '') {
      setOpenKeys([]);
      navigate(`${key}/`)
    } else {
      navigate(`${key}/`)
    }
  }

  const expandIcon = ({ isOpen }) => {
    if (isOpen) {
      return <MdOutlineArrowDropUp style={{ fontSize: '24px' }} />;
    } else {
      return <MdOutlineArrowDropDown style={{ fontSize: '24px' }} />;
    }
  };

  return (
    <>

      <Menu
        onClick={onClick}
        expandIcon={expandIcon}
        defaultSelectedKeys={['']}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        mode="inline"
        items={items}
      />
    </>
  )
}

export default SideMenu
