import PageNotFound from '../Components/PageNotFound'
import UserSignin from '../../Modules/Auth/Container/index'

import { Dashboard } from '../../Modules/Dashboard'
import { AddRooms } from '../../Modules/Rooms/AddRooms'
import { ViewRooms } from '../../Modules/Rooms/ViewRooms'
import { EditCustomer } from '../../Modules/Customer/EditCustomer'
import { AddBooking } from '../../Modules/Booking/AddBooking'
import { VacateRooms } from '../../Modules/Booking/VacateRooms'

import AddCustomerForm from '../../Modules/Customer/AddCustomer/Container/index'

export const anonymous = [
  {
    routePath: '/signin',
    Component: UserSignin,
  },
]

export const authenticated = [
    {
    routePath: '/',
    Component: Dashboard,
  },
  {
    routePath: '/add_rooms',
    Component: AddRooms,
  },
  {
    routePath: '/view_rooms',
    Component: ViewRooms,
  },
  {
    routePath: '/add_customer',
    Component: AddCustomerForm,
  },
  {
    routePath: '/view_customer',
    Component: EditCustomer,
  },
  {
    routePath: '/add_booking',
    Component: AddBooking,
  },
  {
    routePath: '/vacate_room',
    Component: VacateRooms,
  },
  {
    routePath: '*',
    Component: PageNotFound,
  },
]
