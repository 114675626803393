import React, { useEffect, useState } from 'react'
import {
  MainContentHolder,
  MainContentTitle,
  MainContentWrapper,
  StatusLabel,
  StatusValue,
  Cleaned,
  ButtonHolder,
  StatusHolder,
  StatusWrapper,
  StatusColorHolder,
  StatusColorName,
} from './Styled'
import { Row } from '../../../../Components/Row'
import { Col, Tag } from 'antd'
import { StatusColorCode } from './Data'
import Button from '../../../../Components/Form/Button'
import { RoomBookingForm } from './RoomBookingForm'
import { Modal } from '../../../../Components/Modal'
import { ViewBookedDetails } from './ViewBookedDetails'
import { RoomCleanUpdate } from './RoomCleanUpdate'
import { MultipleRoomBooking } from './MultipleRoomBooking'
import { CustomSpin } from '../../../../Components/CustomSpin'
import request from '../../../../utils/request'
import { toast } from 'react-toastify'

export const RoomStatus = ({ selectedRoom, data, UpdateTrigger }) => {

  //   ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [roomsFilter, setRoomsFilter] = useState([]);
  const [changeFilter, setChangeFilter] = useState(null);

  const [roomsData, setRoomsData] = useState([]);

  useEffect(() => {
    setRoomsData(data)
  }, [data])


  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const setRooms = () => {
    fetchFilter();
  }
  const fetchFilter = () => {

    request.get('get/filter')
      .then((response) => {
        setRoomsFilter(response.data)
      }).catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchFilter();
  }, [])

  const CloseModal = () => {
    handleOk();
  }



  const BookingRoom = (record) => {
    setModalTitle("Book Rooms");
    setModalContent(<RoomBookingForm record={record} setRooms={setRooms} CloseModal={CloseModal} UpdateTrigger={UpdateTrigger} />);
    showModal();
  }

  const ViewBookerRoom = (record) => {
    setModalTitle("View Details");
    setModalContent(<ViewBookedDetails />);
    showModal();
  }

  const ChangeCleanUpdate = (record) => {
    const newValue = { ...record, vacate: false, available: true, booking: false, cleaned_uncleaned: true }

    request.post(`home/update/${record?.roomNo}`, newValue)
      .then((response) => {
        UpdateTrigger();
        fetchFilter();
        toast.success('Room Updated Successfully')
      }).catch(error => {
        console.log(error);
      });
  }

  const BookMultipleRooms = () => {

    setModalTitle("Book Multiple Rooms");
    setModalContent(<MultipleRoomBooking CloseModal={CloseModal} roomsFilter={roomsFilter} UpdateTrigger={UpdateTrigger} />);
    showModal();
  }

  const room = roomsData.flatMap((floor) => floor.room_details).find((room) => room.roomNo === selectedRoom);

  return (
    <MainContentWrapper>
      <MainContentTitle>Room Status</MainContentTitle>
      {
        roomsData.length != 0 ? (
          <MainContentHolder>
            <Row gutter={[18, 18]}>
              <Col span={12}>
                <StatusLabel>Room Number&nbsp;:</StatusLabel>
              </Col>
              <Col span={12}>
                <StatusValue>{room.roomNo}</StatusValue>
              </Col>

              <Col span={12}>
                <StatusLabel>Ac Type&nbsp;:</StatusLabel>
              </Col>
              <Col span={12}>
                <StatusValue>{room.ac_or_nonac}</StatusValue>
              </Col>

              <Col span={12}>
                <StatusLabel>Room Category&nbsp;:</StatusLabel>
              </Col>
              <Col span={12}>
                <StatusValue>
                  {
                    room.category.map((cate, index) => {
                      return (
                        <Tag color={'geekblue'} style={{ fontSize: '8px', fontWeight: '600' }} key={index}>
                          {cate.toUpperCase()}
                        </Tag>
                      )
                    })
                  }
                </StatusValue>
              </Col>

              <Col span={12}>
                <StatusLabel>No. Beds&nbsp;:</StatusLabel>
              </Col>
              <Col span={12}>
                <StatusValue>{room.no_of_bed}</StatusValue>
              </Col>

              <Col span={12}>
                <StatusLabel>Room Clean&nbsp;:</StatusLabel>
              </Col>
              <Col span={12}>
                <StatusValue>
                  {
                    room.cleaned_uncleaned ?
                      (
                        <div><Cleaned className="cleaned"></Cleaned> <span>Cleaned</span></div>
                      ) :
                      (
                        <div><Cleaned ></Cleaned> <span>Not Cleaned</span></div>
                      )
                  }
                </StatusValue>
              </Col>
            </Row>

            <ButtonHolder>
              {/* {
                room.booking &&
                (
                  <Button.Primary text={'Update Status'} onClick={() => ChangeCleanUpdate(room)} />
                )
              } */}
              {
                room.available &&
                (
                  <Button.Success text={'Room Booking'} onClick={() => BookingRoom(room)} />
                )
              }
              {
                room.cleaned_uncleaned ? '' :
                  <Button.Primary text={'Update Status'} onClick={() => ChangeCleanUpdate(room)} />
              }

              <Button.Primary text={'Multiple Booking'} onClick={() => BookMultipleRooms(room)} />
            </ButtonHolder>

            <StatusHolder>
              {
                StatusColorCode.map((item,index) => {
                  return (
                    <StatusWrapper key={index}>
                      <StatusColorHolder color={item.color} />
                      <StatusColorName>{item.name}</StatusColorName>
                    </StatusWrapper>
                  )
                })
              }
            </StatusHolder>
          </MainContentHolder>
        )
          :
          (<CustomSpin />)
      }

      <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={800} modalTitle={modalTitle} modalContent={modalContent} />
    </MainContentWrapper>
  )
}
