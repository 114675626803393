export const roomType = [
    {
        label: 'Ac',
        value: 'Ac',
    },
    {
        label: 'Non-Ac',
        value: 'Non-Ac',
    },
];

export const cleanStatus = [
    { value: true, label: 'Yes' },
];

export const FloorDetails = [
    { value: 'A', label: 'GROUND FLOOR' },
    { value: 'B', label: 'FIRST FLOOR' },
    { value: 'C', label: 'SECOND FLOOR' },
];

export const categoryOption = [
    { value: 'Standard', label: 'Standard' },
    { value: 'Deluxe', label: 'Deluxe' },
    { value: 'Super Deluxe', label: 'Super Deluxe' },
    { value: 'Suite', label: 'Suite' },
];