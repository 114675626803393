import React, { Fragment } from 'react'
import { RoomsTable } from './Partials/RoomsTable'

export const ViewRooms = () => {
    return (
        <Fragment>
            <RoomsTable />
        </Fragment>
    )
}

