
import { connect } from 'react-redux'
import { AddCustomer } from '..'

import selectors from '../../selectors'
import { setCustomer,fetchCustomers } from '../../action'

const mapStateToProps = state => ({
  // customer: selectors.getCustomer(state),
})

const mapDispatchToProps = {
    setCustomer,
    fetchCustomers,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer)
