import { FaUsers,FaBed } from "react-icons/fa";
import { IoBedOutline } from "react-icons/io5";

export const DashCardData = [
    {
        key:'1',
        name:'Total Customers',
        value:'',
        icon:<FaUsers/>,
        bgColor:'#f44849',
        bgSecondaryColor:'#ffe8e8',
        textColor:'#e16f74',
    },
    {
        key:'2',
        name:'Total Rooms',
        value:'',
        icon:<FaBed/>,
        bgColor:'#547DDE',
        bgSecondaryColor:'#E7EDFF',
        textColor:'#5A73BA',
    },
    {
        key:'3',
        name:'Available Rooms',
        value:'',
        icon:<IoBedOutline/>,
        bgColor:'#0EBB0A',
        bgSecondaryColor:'#BCFFBF',
        textColor:'#0CCF08',
    },
    {
        key:'4',
        name:'Booked Rooms',
        value:'',
        icon:<FaBed/>,
        bgColor:'#4db1f6',
        bgSecondaryColor:'#e9f5ff',
        textColor:'#93cce8',
    },
]

// export const DashCardData = [
//     {
//         key:'1',
//         name:'No. Customers',
//         value:25,
//         icon:<FaUsers/>,
//         bgColor:'#D35056',
//         bgSecondaryColor:'#FAE8E7',
//         textColor:'#DA7E81',
//     },
//     {
//         key:'2',
//         name:'Available Rooms',
//         value:20,
//         icon:<IoBedOutline/>,
//         bgColor:'#F0C063',
//         bgSecondaryColor:'#FDF1DA',
//         textColor:'#F1CC87',
//     },
//     {
//         key:'3',
//         name:'No. Today Booked Rooms',
//         value:5,
//         icon:<FaBed/>,
//         bgColor:'#547DDE',
//         bgSecondaryColor:'#E7EDFF',
//         textColor:'#5A73BA',
//     },
//     {
//         key:'4',
//         name:'No. Customers',
//         value:25,
//         icon:<FaUsers/>,
//         bgColor:'#5DABDE',
//         bgSecondaryColor:'#E6F4FF',
//         textColor:'#6CB8DA',
//     },
// ]