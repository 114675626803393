import React from 'react'
import { CustomerTable } from './Partials/CustomerTable'

export const EditCustomer = () => {
  return (
    <div>
      <CustomerTable/>
    </div>
  )
}
