import { Col, Form, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react'
import { CustomInputNumber } from '../../../../Components/Form/CustomInputNumber';
import CustomRadioButton from '../../../../Components/Form/CustomRadioButton';
import { Select } from '../../../../Components/Form/Select';
import CustomCheckbox from '../../../../Components/Form/CustomCheckBox';
import Flex from '../../../../Components/Flex';
import Button from '../../../../Components/Form/Button';
import { FloorDetails, categoryOption, cleanStatus, roomType } from '../../../Rooms/AddRooms/Partials/Data';
import { Row } from '../../../../Components/Row';
import Input from '../../../../Components/Form/Input';
import { genderData, verificationData } from './Data';
import { phoneValidator } from '../../../../utils/NumberValidator';
import { TextAreas } from '../../../../Components/Form/TextArea';
import request from '../../../../utils/request'
import {toast} from 'react-toastify'

export const AddCustomerForm = ({ customerdata, customer, handleSubmit, handleOk, Updatefielscheck }) => {

  const [showAadhaar, setShowAadhaar] = useState(false);
  const [showPassPort, setShowPassPort] = useState(false);
  const [showDrivingLicence, setShowDrivingLicence] = useState(false);
  const [CheckedValues, setCheckedValues] = useState([]);


  const handleCheckboxChange = (checkedValues) => {

    setShowAadhaar(checkedValues.includes('aadhaar'));
    setShowPassPort(checkedValues.includes('passport'));
    setShowDrivingLicence(checkedValues.includes('driving_licence'));
    setCheckedValues(checkedValues);
  };

  const [form] = Form.useForm();

  // useEffect(() => {
  //   form.setFieldsValue(customerdata)
  // }, [customerdata])

  useEffect(() => {
    form.setFieldsValue(customerdata);

    if (customerdata && customerdata.verification) {
      setShowAadhaar(customerdata.verification.includes('aadhaar'));
      setShowPassPort(customerdata.verification.includes('passport'));
      setShowDrivingLicence(customerdata.verification.includes('driving_licence'));
    }
  }, [customerdata]);


  const updateCustomer = (record, id) => {
    request.put(`customer/edit/${id}`, record)
      .then((response) => {
        Updatefielscheck();
        // setCustomerList(response.data)
      }).catch(error => {
        console.log(error);
      });
  };

  const onFinish = (values) => {
    form.resetFields();
    if (customerdata) {
      updateCustomer(values, customerdata.customer_id)
      toast.info('Customer Details Updated Successfully')
    }
    else {
      form.resetFields();
      fetchUsers(values)
      toast.success('Customer Added Successfully')
    }
  };

  const fetchUsers = (values) => {
    request.post('customer/save', values)
      .then((response) => {
       
      if (handleSubmit) {
        form.resetFields();
        handleSubmit();
      }
    }).catch(error => {
      console.log(error);
    });
  };

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};
const onRest = () => {
  if (handleSubmit) {
    form.resetFields();
    handleSubmit();
  }
}


return (

  <Form name="add_customer"
    form={form}
    labelCol={{
      span: 24,
    }}
    wrapperCol={{
      span: 24,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Row gutter={[24, 24]}>

      <Col span={24} md={12}>
        <Input
          label={'Customer Name'}
          name={'name'}
          placeholder={'Enter Customer Name'}
          required
          rules={[
            {
              required: true,
              message: 'Please enter Customer Name!',
            },
          ]}
        />
      </Col>

      <Col span={24} md={12}>
        <CustomRadioButton
          label={'Gender'}
          name={'gender'}
          options={genderData}
          required
          rules={[
            {
              required: true,
              message: 'Please Select any gender',
            },
          ]}
        />
      </Col>

      <Col span={24} md={12}>
        <CustomInputNumber
          label={'Phone Number'}
          name={'phone'}
          placeholder={'Enter Phone Number'}
          precision={0}
          required
          rules={[
            {
              required: true,
              message: 'Please enter Phone Number!',
            },
          ]}
        />
      </Col>

      <Col span={24} md={12}>
        <Input
          label={'National'}
          name={'national'}
          placeholder={'Enter National'}
          required
          rules={[
            {
              required: true,
              message: 'Please enter National!',
            },
          ]}
        />
      </Col>

      <Col span={24} md={12}>
        <Input
          label={'State'}
          name={'state'}
          placeholder={'Enter State'}
          required
          rules={[
            {
              required: true,
              message: 'Please enter State!',
            },
          ]}
        />
      </Col>

      <Col span={24} md={12}>
        <CustomCheckbox
          options={verificationData}
          name={'verification'}
          onChange={handleCheckboxChange}
          label={'Category'}
          rules={[
            {
              required: true,
              message: 'Please select any Verfication',
            }
          ]}
        />
      </Col>

      {showAadhaar && (
        <Col span={24} md={12}>
          <CustomInputNumber
            label={'Aadhaar Number'}
            name={'aadhaar'}
            placeholder={'Enter Aadhaar Number'}
            precision={0}
            required
            rules={[
              {
                required: true,
                message: 'Please enter Aadhaar Number',
              }
            ]}
          />
        </Col>
      )}

      {showPassPort && (
        <Col span={24} md={12}>
          <Input
            label={'Passport Number'}
            name={'passport'}
            placeholder={'Enter Passport Number'}
            required
            rules={[
              {
                required: true,
                message: 'Please enter Passport Number',
              }
            ]}
          />
        </Col>
      )}

      {showDrivingLicence && (
        <Col span={24} md={12}>
          <Input
            label={'Driving Licence Number'}
            name={'driving_licence'}
            placeholder={'Enter Driving Licence Number'}
            required
            rules={[
              {
                required: true,
                message: 'Please enter Driving Licence',
              }
            ]}
          />
        </Col>
      )}

      <Col span={24} md={12}>
        <TextAreas
          label={'Address'}
          name={'address'}
          placeholder={'Enter Address'}
          required
          rules={[
            {
              required: true,
              message: 'Please enter Address',
            }
          ]}
        />
      </Col>

      <Flex W_100 center gap={'20px'}>{
        customerdata ?
          (
            <Button.Success text={'Update'} htmlType="submit" />
          )
          :
          (
            <Button.Success text={'Save'} htmlType="submit" />
          )
      }
        <Button.Danger text={'Clear'} onChange={onRest} htmlType="reset" />
      </Flex>
    </Row>
  </Form>
)
}
