import React, { useEffect, useState } from 'react'
import { FormTitle } from '../../../../Components/FormTitle'
import { Table } from '../../../../Components/Table'
import { TableData } from './Data';
import { Tag } from "antd";
import { Fragment } from "react";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { Modal } from '../../../../Components/Modal';
import { AddCustomerForm } from '../../AddCustomer/Partials/AddCustomerForm';
import { IconHolder } from '../../../../Components/CommonStyle';
import request from '../../../../utils/request';
import { CustomSpin } from '../../../../Components/CustomSpin';

export const CustomerTable = () => {

    const [customerList, setCustomerList] = useState([])
    const [reload, setReload] = useState(false)

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchCustomer = () => {

        request.get('customer')
        .then((response) => {
            setCustomerList(response.data)
        }).catch(error => {
            console.log(error);
        });
    };

    const Updatefielscheck= () =>{
        fetchCustomer()
        handleOk()
    }

    const deleteCustomer = (record) => {
        request.delete(`customer/delete/${record.customer_id}`)
        .then((response) => {
            setReload(!reload)
            // setCustomerList(response.data)
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        fetchCustomer();
    }, [])

    useEffect(() => {
        fetchCustomer();
    }, [reload])

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const TableColumn = [
        {
            title: <span>Sl&nbsp;No</span>,
            render: (value, item, index) => index + 1,
        },
        {
            title: <span>Customer&nbsp;Id</span>,
            dataIndex: 'customer_id',
        },
        {
            title: <span>Customer&nbsp;Name</span>,
            dataIndex: 'name',
        },
        {
            title: <span>Gender</span>,
            dataIndex: 'gender',
        },
        {
            title: <span>Phone&nbsp;Number</span>,
            dataIndex: 'phone',
        },
        {
            title: 'National',
            dataIndex: 'national',
        },
        {
            title: 'State',
            dataIndex: 'state',
        },
        {
            title: 'Verification',
            dataIndex: 'verification',
            render: (_, { verification }) => (
                <Fragment>
                    {
                        verification.map((ver) => {
                            return (
                                <Tag color={'geekblue'} style={{ fontSize: '12px',  }} key={ver}>
                                    {ver.toUpperCase()}
                                </Tag>
                            )
                        })
                    }
                </Fragment>
            )
        },
        {
            title: 'Action',
            render: (record) => (
                <Fragment>
                    <IconHolder onClick={() => EditRooms(record)}>
                        <FiEdit color="green" />
                    </IconHolder>
                    {/* <IconHolder onClick={() => DeleteRooms(record)}>
                        <AiOutlineDelete color="red" />
                    </IconHolder> */}
                </Fragment>
            )
        }
    ]

    const EditRooms = (record) => {
        setModalTitle("Edit Rooms");
        setModalContent(<AddCustomerForm handleOk={handleOk} Updatefielscheck={Updatefielscheck} customerdata={record}/>);
        showModal();
    }

    const DeleteRooms = (record) => {
        deleteCustomer(record)
    }

    const rowkey = (customerList) => customerList.customer_id;

    return (
        <div>
            <FormTitle title={'View / Edit Customers'} />

            {
                customerList.length != 0 ?
                    (
                        <Table columns={TableColumn} data={customerList} rowKey={rowkey}/>
                    ) :
                    (
                        <CustomSpin/>
                    )
            }

            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={600} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

