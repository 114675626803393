import React from 'react'
import styled from 'styled-components'
import { THEME } from '../theme'

const StyledFormTitle = styled.h3`
    font-size:1.5rem;
    text-align:center;
    letter-spacing:1px;
    color:${THEME.red};
    font-variant:small-caps;
    margin:25px;
`

export const FormTitle = ({title}) => {
  return (
    <StyledFormTitle>{title}</StyledFormTitle>
  )
}
