import React, { useEffect, useState } from 'react'
import { CategoryWrapper, FormSubTitle, MainContentWrapper } from './Styled'
import { Checkbox, Col, Form } from 'antd'
import Input from '../../../../Components/Form/Input';
import Flex from '../../../../Components/Flex';
import { Row } from '../../../../Components/Row';
import Button from '../../../../Components/Form/Button';
import Label from '../../../../Components/Form/Label';
import { Select } from '../../../../Components/Form/Select';
import { MemberDetails } from './Data';
import { TextAreas } from '../../../../Components/Form/TextArea';
import { CustomInputNumber } from '../../../../Components/Form/CustomInputNumber';
import { CustomDatePicker } from '../../../../Components/Form/CustomDatePicker';
import request from '../../../../utils/request';
import { Modal } from '../../../../Components/Modal';
import { AddCustomerForm } from '../../../Customer/AddCustomer/Partials/AddCustomerForm';
import { toast } from 'react-toastify';


export const RoomBookingForm = ({ record, CloseModal, setRooms, UpdateTrigger }) => {

    const [getDays, setGetDays] = useState(false)
    const [memberList, setMemberList] = useState([])
    const [customerList, setCustomerList] = useState([])
    const [phnoselect, setPhoselect] = useState({});
    const [form] = Form.useForm();

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchCustomer = (values) => {
        request.get('customer')
            .then((response) => {
                setCustomerList(response.data)
            }).catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        form.setFieldsValue(record);
    }, [record]);

    useEffect(() => {
        fetchCustomer();
    }, [])

    // const fetchRoomDetls = (values) => {
    //     console.log(values,'hhhhhhhhhhhhh');
    //     request.get('get/filter')
    //         .then((response) => {
    //             console.log(response.data);
    //         }).catch(error => {
    //             console.log(error);
    //         });
    // };

    // useEffect(() => {
    //     fetchRoomDetls();
    // }, [])

    const handleSubmit = () => {
        fetchCustomer();
        handleCancel(false);
    }
    const onFinish = (values) => {
        PostRoomBooking(values);
        toast.success('Room Booked Successfully')

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const GetBedValue = (checkedValues) => {
        setGetDays(checkedValues.includes('beds'));
        if (getDays) {
            form.setFieldsValue({ extradebs: '' });
        }
    }

    const GetMemberList = (checkedValues) => {
        setMemberList(checkedValues);
    }

    const handleCheckboxChange = (value) => {
        if (memberList.includes(value)) {
            // Clear the input value if the checkbox is being unchecked
            form.setFieldsValue({ [value]: undefined });
        }
    };
    const validateExtraBeds = (rule, value, callback) => {
        if (getDays && !value) {
            callback('Extra Beds required');
        } else {
            callback();
        }
    };

    // const cusName = customerList.map(user => {
    //     return {
    //         label: user.phone,
    //         value: user.phone,
    //     };
    // });

    // const SelectUser = (e)=>{
    //     console.log(e,'fffffff')
    // }
    // console.log(customerList,'jjjjjjjjjjj')
    // console.log(cusName,'jjjjjjjjjjj')

    useEffect(() => {
        form.setFieldsValue({ customer_id: phnoselect.customer_id })
        form.setFieldsValue({ name: phnoselect.name })
        form.setFieldsValue({ address: phnoselect.address })
        form.setFieldsValue({ phone: phnoselect.phone })

    }, [phnoselect])

    const BookingPhoneNo = customerList?.map(user => ({ label: user.name, value: user.name }));

    const handleSelected = (value) => {
        const selectedNumber = customerList.find((user) => user.name === value)
        setPhoselect(selectedNumber);
    }

    const AddCustomerbtn = () => {
        setModalTitle("Add New Customers")
        setModalContent(<AddCustomerForm handleSubmit={handleSubmit} handleOk={handleOk} />);
        showModal();

    }

    //===================Room-Booking-Post=========================//

    const PostRoomBooking = (values) => {
        request.post('save/book', values)
            .then((response) => {
                form.resetFields()
                CloseModal();
                UpdateTrigger();
                setRooms();
                setModalTitle("Room Booking Posted Successfully")
            }).catch(error => {
                console.log(error);
            });

    }



    return (
        <MainContentWrapper>
            <Form name="room_booking"
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <FormSubTitle>Room Details</FormSubTitle>
                <hr style={{ marginBottom: '20px' }} />

                <Row gutter={[24, 24]}>

                    <Col span={24} md={12}>
                        <Input
                            label={'Room Number'}
                            name={'roomNo'}
                            disabled
                        />
                    </Col>

                    <Col span={24} md={12}>
                        <Input
                            label={'Ac Type'}
                            name={'ac_or_nonac'}
                            disabled
                        />
                    </Col>

                    <Col span={24} md={12}>
                        <Input
                            label={'Number of Beds'}
                            name={'no_of_bed'}
                            disabled
                        />
                    </Col>

                    <Col span={24} md={12}>
                        <Label>Category</Label>
                        <CategoryWrapper>
                            {
                                record.category.map((item, i) => (
                                    <span key={i}>{item}</span>
                                ))
                            }
                        </CategoryWrapper>
                    </Col>
                </Row>

                <FormSubTitle>Customer Details</FormSubTitle>
                <hr style={{ marginBottom: '20px' }} />

                <Row gutter={[24, 24]}>

                    <Col span={24} md={12}>
                        <Input
                            label={'Customer Id'}
                            name={'customer_id'}
                            disabled
                        />
                    </Col>
                    <Col span={24} md={12}>
                        <Select
                            label={'Customer Name'}
                            name={'name'}
                            options={BookingPhoneNo}
                            showSearch
                            onChange={handleSelected}
                            rules={[
                                {
                                    required:true,
                                    message:'This Field is requird'
                                }
                            ]}
                        />
                    </Col>
                    <Col span={24} md={12}>
                        <Input
                            label={'Phone Number'}
                            name={'phone'}
                            disabled
                        />
                    </Col>
                    <Col span={24} md={12}>
                        <TextAreas
                            label={'Address'}
                            name={'address'}
                            disabled
                        />
                    </Col>
                    <Col span={24} md={12}>
                        <Button.Success text={'Add New customers'} onChange={handleSubmit} onClick={() => AddCustomerbtn()} />
                    </Col>
                </Row>
                <FormSubTitle>Members Details</FormSubTitle>
                <hr style={{ marginBottom: '20px' }} />

                <h2>Number of Staying Members</h2>
                <Row gutter={[12, 12]}>
                    <Col span={24} md={12}>

                        <Input
                            label={'No. Persons'}
                            name={'no_of_person'}
                            rules={[
                                {
                                    required:true,
                                    message:'This Field is requird'
                                }
                            ]}
                        />
                    </Col>
                </Row>
                <Form.Item
                    name="memberList">
                    <Checkbox.Group style={{ width: '100%', marginTop: '20px' }} onChange={GetMemberList}>
                        <Row gutter={[24, 24]}>
                            {
                                MemberDetails.map((item) => (
                                    <Col span={24} md={8} key={item.key}>
                                        <Row gutter={[24, 24]}>
                                            <Col span={24}>
                                                <Checkbox value={item.value} onChange={() => handleCheckboxChange(item.value)}><h3>{item.title}</h3></Checkbox>
                                            </Col>
                                            <Col span={24}>
                                                <CustomInputNumber name={item.name} disabled={!memberList.includes(item.value)} rules={
                                                    memberList.includes(item.value) ? [{ required: true, message: 'Please enter a value' }] : [{ required: false, message: '' }]
                                                } />
                                            </Col>
                                        </Row>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Checkbox.Group>
                </Form.Item>

                <FormSubTitle>Staying Details</FormSubTitle>
                <hr style={{ marginBottom: '20px' }} />

                <Row gutter={[24, 24]}>

                    <Col span={24} md={12}>
                        <CustomDatePicker
                            label={'Booking Date'}
                            name={'booking_date'}
                            showTime
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Date!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Checkbox.Group style={{ width: '100%' }} onChange={GetBedValue}>
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <Row gutter={[24, 24]}>
                                        <Col span={24}>
                                            <Checkbox value={"beds"}><h3>Extra Beds</h3></Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <CustomInputNumber name={'extradebs'} disabled={!getDays} rules={[{ validator: validateExtraBeds }]} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Checkbox.Group>
                    </Col>

                    <Col span={24} md={12}>
                        <Input
                            label={'Advance Amount'}
                            name={'advance'}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill Amount!',
                                }
                            ]}
                        />
                    </Col>

                    <Col span={24} md={12}>
                        <CustomInputNumber
                            label={'No. Days'}
                            name={'no_of_days'}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill Number of Days!',
                                }
                            ]}
                        />
                    </Col>

                </Row>

                <div style={{ margin: '30px' }}>
                    <Flex W_100 center gap={'20px'}>
                        <Button.Success text={'Save'} htmlType="submit" />
                        <Button.Danger text={'Clear'} htmlType="reset" />
                    </Flex>
                </div>

            </Form>
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={800} modalTitle={modalTitle} modalContent={modalContent} />
        </MainContentWrapper>
    )
}

