import React, { useEffect, useState } from 'react'
import { FormTitle } from '../../../../Components/FormTitle'
import { Table } from '../../../../Components/Table'
import { Col, Popconfirm, Tag } from "antd";
import { BsBoxArrowRight } from "react-icons/bs";
import { Modal } from '../../../../Components/Modal';
import request from '../../../../utils/request';
import dayjs from 'dayjs'
import Flex from '../../../../Components/Flex';
import Button from '../../../../Components/Form/Button';
import { Row } from '../../../../Components/Row';
import { AiOutlineEye,AiOutlineDelete} from 'react-icons/ai';
import { Fragment } from 'react';
import { toast } from 'react-toastify';
import { ModalViewContent } from './ModalViewContent';

export const VacateRoomsTable = () => {

    const [vacate, setVacate] = useState([]);

    useEffect(() => {
        roomVacate();
    }, [])

    // const [currentDateTime, setCurrentDateTime] = useState(new Date());

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setCurrentDateTime(new Date());
    //     }, 1000); // Update every second

    //     return () => clearInterval(intervalId); // Clean up the interval on unmount
    // }, []);

    // const formattedDateTime = currentDateTime.toLocaleString();

    const roomformVacate = (record) => {
        request.post(`vacate/${record.booking_id}`, { id: record.booking_id })
            .then((response) => {
                roomVacate();
                toast.success('Room Vacated Successfully')
            }).catch(error => {
                console.log(error);
            });
    };

    const deleteBooking = (record) => {
        request.delete(`deleteBooking/${record.booking_id}`, { id: record.booking_id })
            .then((response) => {
                roomVacate();
                toast.success('Booking Deleted Successfully')
            }).catch(error => {
                console.log(error);
            });
    };


    const roomVacate = () => {
        request.get('get/vacate')
            .then((response) => {
                setVacate(response?.data)
            }).catch(error => {
                console.log(error);
            });
    };


    useEffect(() => {
        setVacate(vacate);
    }, [vacate])


    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const onViewCustomers = (record, relievingDate) => {
        setModalContent(<ModalViewContent record={record} relievingDate={relievingDate} />);
        setModalTitle("View Details");
        showModal();
    }
    const Tabledata = vacate?.map((item)=>(
        {
            name:item.name,
            roomNo:item.roomNo,
            multi_rooms:item.multi_rooms,
            no_of_person:item.no_of_person,
            no_of_days:item.no_of_days,
            booking_date:item.booking_date,
            advance:item.advance,
            phone:item.phone,
            booking_id:item.booking_id,
        }
    ))


    const TableColumn = [
        {
            title: <span>Sl&nbsp;No</span>,
            render: (value, item, index) => index + 1,
        },
        {
            title: <span>Room&nbsp;Numbers</span>,
            render: (record) => {
                return (
                    <Fragment>
                        {record.roomNo !== 0 && <span style={{ fontSize: '14px', }}>{record.roomNo}</span>}
                        {record.multi_rooms.length !== 0 &&
                            record.multi_rooms.map((room,index) => (
                                <span style={{ fontSize: '14px', }} key={index}> {room}&nbsp;</span>
                            ))
                        }
                    </Fragment>
                );
            },
        },
        {
            title: <span>Customer&nbsp;Name</span>,
            dataIndex: 'name',
        },
        {
            title: 'CheckIn',
            dataIndex: 'booking_date',
            render: (booking_date) => {
                return dayjs(booking_date).format('DD-MM-YYYY hh:mm:ss A');
            },
        },
        {
            title: <span>No.of&nbsp;Persons</span>,
            dataIndex: 'no_of_person',
        },
        {
            title: 'Checkout',
            dataIndex: 'booking_date',
            render: (booking_date, record) => {
                const relievingDate = dayjs(booking_date).add(record?.no_of_days, 'day');
                return (
                    <span>
                        {dayjs(relievingDate).format('DD-MM-YYYY hh:mm:ss A')}
                    </span>
                );
            },
        },
        {
            title: 'Status',
            render: (record) => {
                const relievingDate = dayjs(record.booking_date).add(record?.no_of_days, 'day');
                const currentDate = dayjs();
                const isPastRelievingDate = currentDate.isAfter(relievingDate);
                return <span>{isPastRelievingDate ?
                    <Tag color="red">
                        TimeLimit Reached
                    </Tag> :
                    <Tag color="green">
                        Time Remains
                    </Tag>
                } </span>;
            },
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone',
        },
        {
            title: 'Action',
            render: (_, record) => {
                const relievingDate = dayjs(record.booking_date).add(record?.no_of_days, 'day');
                return (
                    (
                        <Flex center gap={'10px'}>
                            <Popconfirm title="Sure to Vacate Room?" onConfirm={() => roomformVacate(record)}>
                                <a><BsBoxArrowRight /></a>
                            </Popconfirm>
                            <span style={{ cursor: 'pointer' }}><AiOutlineEye onClick={() => onViewCustomers(record, relievingDate)} color='green' /></span>
                            <Popconfirm title="Do you want to delete?" onConfirm={() => deleteBooking(record)}>
                                <a><AiOutlineDelete /></a>
                            </Popconfirm>
                        </Flex>

                    )
                )
            }

        }
    ]

const rowkey = (Tabledata) =>Tabledata.booking_id;

    return (
        <div>
            <FormTitle title={'Room Vacate'} />
            <Table columns={TableColumn} data={Tabledata} rowKey={rowkey} />
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

