import React, { Fragment } from 'react'
import DashCard from './Partials/DashCard'
import { FormTitle } from '../../Components/FormTitle'
import Flex from '../../Components/Flex'
import { Col } from 'antd'

export const Dashboard = () => {
  return (
    // <div style={{background:'red',width:'100%'}}>
    // </div>
    
    <Fragment>
      <FormTitle title={'Add Rooms'} />
      <Flex center>
        <Col span={24}>
          <DashCard />

          {/* <CustomCard>
            <RoomsFillForm />
          </CustomCard> */}
        </Col>
      </Flex>
    </Fragment>
  )
}
