import React, { Fragment } from 'react'
import Flex from '../../../Components/Flex'
import { Col } from 'antd'
import { FormTitle } from '../../../Components/FormTitle'
import CustomCard from '../../../Components/CustomCard'
import { AddCustomerForm } from './Partials/AddCustomerForm'

export const AddCustomer = ({customer,fetchCustomers}) => {
    return (
        <Fragment>
            <FormTitle title={'Add Customer Details'} />
            <Flex center>
                <Col span={24} lg={16}>
                    <CustomCard>
                        <AddCustomerForm customer={customer} fetchCustomers={fetchCustomers}/>
                    </CustomCard>
                </Col>
            </Flex>
        </Fragment>
    )
}
