import React, { useEffect, useState } from 'react'
import { Col, Form } from 'antd'
import Flex from '../../../../Components/Flex';
import { CustomInputNumber } from '../../../../Components/Form/CustomInputNumber';
import { Row } from '../../../../Components/Row';
import CustomRadioButton from '../../../../Components/Form/CustomRadioButton';
import Button from '../../../../Components/Form/Button';
import { Select } from '../../../../Components/Form/Select';
import CustomCheckbox from '../../../../Components/Form/CustomCheckBox';
import { FloorDetails, categoryOption, cleanStatus, roomType } from './Data';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';

export const RoomsFillForm = ({ roomdata,handleOk,Updatefielscheck }) => {

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(roomdata)
    }, [roomdata])


    const onFinish = (values) => {
        form.resetFields();
        if (roomdata) {
            editRoom(values,roomdata.roomNo)
        }
        else {
            form.resetFields();
            fetchUsers(values)
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const fetchUsers = (values) => {
        // const newValues = { ...values, available: true, booking: false, vacate: false }
        request.post('home/save', values)
        .then((response) => {
            toast.success('Room Added Successfully')
        }).catch(error => {
            console.log(error);
        });
    };


    const editRoom = (record) => {
        request.put(`edit/${roomdata?.roomNo}`,record).then((response) => {
            Updatefielscheck()
            toast.info('Room Details Updated Successfully')
            handleOk();
            
            // setCustomerList(response.data)
        }).catch(error => {
            console.log(error);
        });
    };

    return (

        <Form name="add_rooms"
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            initialValues={{
                cleaned_uncleaned: true
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Row gutter={[24, 24]}>
                <Col span={24} md={12}>
                    <CustomInputNumber
                        label={'Room Number'}
                        name={'roomNo'}
                        placeholder={'Enter Room Number'}
                        maxlength={'3'}
                        required
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Room Number!',
                            },
                        ]}
                    />
                </Col>

                <Col span={24} md={12}>
                    <CustomRadioButton
                        label={'Ac or Non-Ac'}
                        name={'ac_or_nonac'}
                        options={roomType}
                        required
                        rules={[
                            {
                                required: true,
                                message: 'Please Select any Type',
                            },
                        ]}
                    />
                </Col>

                <Col span={24} md={12}>
                    <Select
                        options={cleanStatus}
                        placeholder={'Cleaning Status'}
                        label={'Cleaning Status'}
                        name={'cleaned_uncleaned'}
                        rules={[
                            {
                                required: true,
                                message: 'Please select any option',
                            }
                        ]}
                    />
                </Col>

                <Col span={24} md={12}>
                    <Select
                        options={FloorDetails}
                        placeholder={'Floor Details'}
                        label={'Floor Details'}
                        name={'floor_details'}
                        rules={[
                            {
                                required: true,
                                message: 'Please select any option',
                            }
                        ]}
                    />
                </Col>

                <Col span={24} md={12}>
                    <CustomCheckbox
                        options={categoryOption}
                        label={'Category'}
                        name={'category'}
                        rules={[
                            {
                                required: true,
                                message: 'Please select any option',
                            }
                        ]}
                    />
                </Col>

                <Col span={24} md={12}>
                    <CustomInputNumber
                        label={'No. Beds'}
                        name={'no_of_bed'}
                        placeholder={'Enter No. Beds'}
                        maxlength={'1'}
                        required
                        rules={[
                            {
                                required: true,
                                message: 'Please enter No. Beds!',
                            },
                        ]}
                    />
                </Col>

                <Flex W_100 center gap={'20px'}>
                    {
                        roomdata ?
                            (
                                <Button.Success text={'Update'} htmlType="submit" />
                            )
                            :
                            (
                                <Button.Success text={'Save'} htmlType="submit" />
                            )
                    }

                    <Button.Danger text={'Clear'} htmlType="reset" />
                </Flex>
            </Row>
        </Form>
    )
}

